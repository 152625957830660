import React from 'react'

import BodyScrollLock from '@emico/body-scroll-lock'
import { ModalSegueLeft } from '@emico/modal-segue'

import FocusScope from './FocusScope'
import SearchMobileModalContent from './SearchMobileModalContent'

interface Props {
  show: boolean
  close: () => void
}

const SearchMobileModal = ({ show, close }: Props) => (
  <BodyScrollLock lock={show}>
    <ModalSegueLeft show={show}>
      <FocusScope>
        <SearchMobileModalContent close={close} />
      </FocusScope>
    </ModalSegueLeft>
  </BodyScrollLock>
)

export default SearchMobileModal
