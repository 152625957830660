import { useIsLoggedIn } from '@emico-hooks/login-token'
import { ButtonUnstyled } from '@emico-react/buttons'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import React, { useState } from 'react'

import { i18n } from '@emico/i18n'
import { ShoppingCartIcon } from '@emico/icons'
import SearchBar from '@emico/search-bar-autocomplete-tweakwise'
import { Container, Logo } from '@emico/ui'

import AccountIcon from '../icons/AccountIcon'
import HeartIcon from '../icons/HeartIcon'
import { getCurrentStoreCountry } from '../lib/getStoreSelectorCountries'
import getInitialSearchKeywords from '../lib/initialSearchKeywords'
import { routes } from '../lib/routes'
import { useStoreConfig } from '../lib/storeConfig'
import theme from '../theme'
import CartQuantity from './CartQuantity'
import NextLink from './NextLink'
import StoreSelectorModal from './StoreSelectorModal'

const GeneralLinks = styled.div`
  padding: ${theme.spacing.md} 0;
  position: relative;
  height: ${theme.sizes.headerHeight};
  background-color: ${theme.colors.background};
  border-bottom: ${theme.borders.default};
  z-index: ${theme.zIndices.navbar};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledSearchBar = styled(SearchBar)`
  position: initial;
  min-width: 300px;
`

const LinkRow = styled.div`
  display: flex;
  align-items: center;
`

const StyledNextLink = styled(NextLink)`
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing.lg};
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes.xs};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  text-decoration: none;
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  &:hover {
    text-decoration: none;
    color: ${theme.colors.primary};
  }

  &:last-child {
    margin-right: 0;
  }
`

const IconWrapper = styled.div`
  position: relative;
`

const iconStyling = css`
  font-size: 20px;
`

const StyledAccountIcon = styled(AccountIcon)`
  ${iconStyling};

  margin-right: ${theme.spacing.sm};
`

const StyledHeartIcon = styled(HeartIcon)`
  ${iconStyling};
`

const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
  ${iconStyling};
`

const MenuDesktopGeneralLinks = () => {
  const isLoggedIn = useIsLoggedIn()
  const { storeConfig } = useStoreConfig()

  const [openStoreSelectorModal, setOpenStoreSelectorModal] =
    useState<boolean>(false)

  const disableEcommerce = storeConfig?.disableEcommerce ?? false

  const currentStoreCountry = getCurrentStoreCountry(
    storeConfig?.code ?? undefined,
  )

  return (
    <>
      <GeneralLinks>
        <Container>
          <Row>
            <NextLink
              href="/"
              ariaLabel={i18n._(t`Homepage`)}
              analyticsContext="header.desktop"
              analyticsName="logo"
            >
              <Logo />
            </NextLink>

            <StyledSearchBar
              keywords
              products
              defaultResultsLabel={t({ message: 'Suggestions' })}
              initialKeywords={getInitialSearchKeywords()}
              useProductsAsKeywordsFallback
            />

            <LinkRow>
              {isLoggedIn ? (
                <StyledNextLink
                  href={routes.account.orderHistory.base}
                  analyticsContext="header.desktop"
                  analyticsName="account.orderHistory"
                >
                  <StyledAccountIcon />

                  <Trans>My account</Trans>
                </StyledNextLink>
              ) : (
                <StyledNextLink
                  href={routes.account.login}
                  analyticsContext="header.mobile"
                  analyticsName="account.logIn"
                >
                  <StyledAccountIcon />

                  <Trans>Log in</Trans>
                </StyledNextLink>
              )}

              <StyledNextLink
                href={routes.wishlist}
                analyticsContext="header.desktop.general"
                analyticsName="wishList"
              >
                <StyledHeartIcon />
              </StyledNextLink>

              {!disableEcommerce && (
                <StyledNextLink
                  href={routes.cart.base}
                  analyticsContext="header.desktop.general"
                  analyticsName="shoppingCart"
                >
                  <IconWrapper>
                    <StyledShoppingCartIcon />

                    <CartQuantity />
                  </IconWrapper>
                </StyledNextLink>
              )}

              <ButtonUnstyled
                analyticsContext="header.desktop.general"
                analyticsName="store.selector.change.country"
                onPress={() => setOpenStoreSelectorModal(true)}
              >
                {currentStoreCountry?.flag}
              </ButtonUnstyled>
            </LinkRow>
          </Row>
        </Container>
      </GeneralLinks>

      <StoreSelectorModal
        show={openStoreSelectorModal}
        close={() => setOpenStoreSelectorModal(false)}
      />
    </>
  )
}

export default MenuDesktopGeneralLinks
