import styled from '@emotion/styled'
import { useFocusManager } from '@react-aria/focus'
import React, { useEffect } from 'react'

import { BackButton, CancelButton } from '@emico/search-bar'
import { StyledBackdrop } from '@emico/search-bar-autocomplete'
import SearchBar from '@emico/search-bar-autocomplete-tweakwise'

import getInitialSearchKeywords from '../lib/initialSearchKeywords'
import { useStoreConfig } from '../lib/storeConfig'
import { StyledInput, StyledSearchIcon } from '../overrides/SearchBar'
import { StyledKeywordSuggestions } from '../overrides/SearchBarKeywordSuggestions'
import theme from '../theme'
import PreviouslyViewedProducts from './PreviouslyViewedProducts'
import SearchMobileModalHeader from './SearchMobileModalHeader'

const ModalBody = styled.section`
  background: ${theme.colors.background};
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ModalContent = styled.div``

const StyledSearchBar = styled(SearchBar)`
  ${StyledBackdrop} {
    display: none;
  }

  ${StyledInput} {
    background-color: ${theme.colors.grayLight};
  }

  ${StyledKeywordSuggestions} {
    padding: 0 ${theme.spacing.lg};
  }

  ${BackButton} {
    svg {
      font-size: 14px;
    }
  }

  ${CancelButton} {
    right: ${theme.spacing.lg};
    transform: translateY(-50%);

    svg {
      font-size: 14px;
    }
  }

  ${StyledSearchIcon} {
    height: 19px;
    width: 19px;
    margin-right: ${theme.spacing.lg};
  }
`

const StyledPreviouslyViewedProducts = styled(PreviouslyViewedProducts)`
  margin: ${theme.spacing.lg} 0 ${theme.spacing['3xl']} ${theme.spacing.lg};
`

interface Props {
  close: () => void
}

const SearchMobileModalContent = ({ close }: Props) => {
  const { storeConfig } = useStoreConfig()
  const focusManager = useFocusManager()
  const disableEcommerce = storeConfig?.disableEcommerce ?? false

  // Put autofocus on search bar input
  useEffect(() => {
    focusManager.focusLast()
  }, [focusManager])

  return (
    <ModalBody>
      <div>
        <SearchMobileModalHeader close={close} />

        <ModalContent>
          <StyledSearchBar
            keywords
            products
            initialKeywords={getInitialSearchKeywords()}
            useProductsAsKeywordsFallback
          />
        </ModalContent>
      </div>

      <StyledPreviouslyViewedProducts disableEcommerce={disableEcommerce} />
    </ModalBody>
  )
}

export default SearchMobileModalContent
