import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { maxWidth } from '@emico/styles'
import { useScroll, ScrollDirection } from '@emico/utils'

import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import MenuDesktopGeneralLinks from './MenuDesktopGeneralLinks'
import MenuDesktopMainLinks from './MenuDesktopMainLinks'

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: ${theme.zIndices.sticky};

  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledMenuDesktopMainLinks = styled(MenuDesktopMainLinks)`
  transform: ${({ show }) => `translateY(${show ? 0 : -100}%)`};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
`

interface Props extends DefaultLayoutStaticData {
  rootCategory: CategoryFragmentWithChildren
}

const HeaderDesktop = ({ websiteData, rootCategory }: Props) => {
  const [scrollDirection, setScrollDirection] = useState<
    ScrollDirection.INIT | ScrollDirection.UP | ScrollDirection.DOWN
  >(ScrollDirection.INIT)
  const [hasActiveCategory, setHasActiveCategory] = useState<boolean>(false)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const headerBoundingRect = headerRef?.current?.getBoundingClientRect()
  const { direction, position } = useScroll()
  const useStickyNav = position.y > (headerBoundingRect?.height ?? 0)

  const handleActiveCategory = (hasActiveCategory: boolean) => {
    setHasActiveCategory(hasActiveCategory)
  }

  useEffect(() => {
    if (scrollDirection !== direction) {
      setScrollDirection(direction)
    }
  }, [direction, scrollDirection])

  useEffect(() => {
    setHasActiveCategory(hasActiveCategory)
  }, [hasActiveCategory])

  return (
    <>
      <Header ref={headerRef}>
        <MenuDesktopGeneralLinks />

        <StyledMenuDesktopMainLinks
          websiteData={websiteData}
          rootCategory={rootCategory}
          show={!useStickyNav || scrollDirection === ScrollDirection.UP}
          handleActiveCategory={handleActiveCategory}
        />
      </Header>
    </>
  )
}

export default HeaderDesktop
