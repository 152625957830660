import { t } from '@lingui/macro'

export default function getInitialSearchKeywords() {
  return [
    t({ message: 'Playbase' }),
    t({ message: 'Buddy' }),
    t({ message: 'Buzzy' }),
    t({ message: 'Champion' }),
    t({ message: 'Favorit' }),
    t({ message: 'Biky' }),
  ]
}
